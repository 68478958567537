@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: black;
  color: white;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



