.App {
  background-color: black;
}

.App-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  margin: 0;
  top: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
}

.App-footer {
  text-align: center;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
  color: white;
  margin: 0;
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  background: black;
}

.App-center {
  width: 100%;
  background:  rgb(22, 22, 22);
}

a {
  color: #0190eb;
}


.contact-text {
  color: rgb(111, 111, 111);
  font-weight: 400;
  font-size: 32px;
  font-family: 'Playfair Display', Georgia, serif;
  line-height: 1.4;
}

.separator{
  border-style: solid;
  border-bottom-width: 1px;
  border-color: rgb(42, 42, 42);
  margin-bottom: 0;
  width: 50px;
  margin-top: 0px;
  box-sizing: content-box;
  display: inline-block;
}

.name-text {
  font-family: 'Source Sans Pro', arial, sans-serif;
  letter-spacing: normal;
  color: rgb(247, 247, 247);
  font-size: 22px;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 24px;
  margin-top: 10px;
}

.adress-text {
  font-size: 16px;
  font-family: 'Source Sans Pro', arial, sans-serif;
  text-transform: none;
  letter-spacing: normal;
  font-weight: 400;
  color: rgb(164, 164, 164);
}

.mail-text {
  font-size: 16px;
  font-family: 'Source Sans Pro', arial, sans-serif;
  text-transform: none;
  letter-spacing: normal;
  font-weight: 400;
  color: rgb(164, 164, 164);
}

.text-center {
  text-align: center;
  font-family: 'Source Sans Pro', arial, sans-serif;
  color: rgb(150, 150, 150);
  line-height: 1.5;
  font-size: 14px;
  text-transform: none;
  letter-spacing: normal;
 }


.card-content {
 text-align: center;
 color: white;
 font-size: calc(5px + 2vmin);
}

.title-text {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  margin-top: 23px;
}

.launching-text {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.2;
  font-family: 'Playfair Display', Georgia, serif;
  letter-spacing: normal;
  text-align: center;
}

.center-text {
  text-align: center;
}


.Linkedin-img {
  width: 20px;
  margin: 10px;
  vertical-align: middle;
}

.Header-text {
  margin-top: 0;
  margin-bottom: 5px;
}

.Contact-norehill {
  max-height: 600px;
  max-width: 900px;
  width: 100%;
  height: 100%;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}




